/* Manjari */

@font-face {
	font-family: "Manjari";
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src: url(Manjari-Regular.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+0D01-0D7F, U+200C, U+200D, U+25CC;
}

/* Manjari Bold */

@font-face {
	font-family: "Manjari";
	font-style: normal;
	font-display: swap;
	font-weight: 700;
	src: url(Manjari-Bold.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+0D01-0D7F, U+200C, U+200D, U+25CC;
}

/* Manjari Thin */

@font-face {
	font-family: "Manjari";
	font-style: normal;
	font-display: swap;
	font-weight: 100;
	src: url(Manjari-Thin.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+0D01-0D7F, U+200C, U+200D, U+25CC;
}
